import React from 'react'

import Layout from '../component/layout'
import Image from '../component/image'
// import Link from '../component/link'
// import Testimonial from '../component/testimonial'
import JourneyOmnidya from '../component/journeyOmnidya'

const Availability = () => {
  return(
    <Layout title="Availability" captions="Join the fastest growing auto insurance company in the West (and soon, the U.S.)." >
      <div className="container-xxl">
        <div className="text-center py-5">
          <div className="header-slogan pb-3">
            Where in the world is Omnidya? 
          </div>
          <p>Check where we are and will be soon</p>
        </div>
        {/* <div className="d-flex justify-content-center">
          <Link className="btn btn-blue btn-pill">
            Home Insurance
          </Link>
          <Link className="btn btn-pill">
            Auto Insurance
          </Link>
        </div> */}

        <div className="text-center p-5">
            <Image src="usa-map.svg" className="img-fluid" />
          {/* <Link to="/california/">
            <Image src="map.png" />
          </Link> */}
        </div>
        {/* <Testimonial /> */}
      </div>
      <JourneyOmnidya gutter />
    </Layout>
  )
}

export default Availability